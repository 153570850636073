<template>
    <div class="badge-container">
        <div v-if="rank" class="badge-box">
            <span class="badge-text" :style="bgColor"><slot/></span>
        </div>
    </div>
</template>

<script>
export default {
    props: ['rank', 'color', 'bg'],
    computed: {
        bgColor() {
            return {
                '--bg-color': this.bg,
                '--font-color': this.color
            };
        },
    }
}
</script>

<style>
    .badge-container {
        display: flex;
        width: 100%;
        min-width: 100px;
    }

    .badge-box {
        display: block;
    }

    .badge-text {
        background-color: var(--bg-color);
        color: var(--font-color);
        border-radius: 3px;
        word-wrap: break-word; 
        word-break: break-all;
        font-size: 0.8em;
        padding: 2px 5px;
        margin: 0px;
        font-weight: bold;
    }
</style>
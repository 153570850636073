import Vue from 'vue'
import Vuex from 'vuex'
import { current_year } from '../config'
import { regions } from '../config'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    selectedSpecies: {},
    language: 'en',
    current_year: current_year,
    selected_region: 'Canada',
    selected_year: null,
  },
  mutations: {
    SET_SELECTED_SPECIES (state, payload) {
      state.selectedSpecies = payload
    },

    SET_LANGUAGE (state, payload) {
      state.language = payload
    },

    SET_YEAR (state, payload) {
      state.selected_year = payload
    },

    SET_REGION (state, payload) {
      state.selected_region = payload
    }
  },
  actions: {
    setSelectedSpecies ({commit}, payload) {
      commit('SET_SELECTED_SPECIES', payload)
    },

    setLanguage ({commit}, payload) {
      commit('SET_LANGUAGE', payload)
    },

    setStoreYear ({commit}, payload) {
      commit('SET_YEAR', payload)
    },

    setStoreRegion ({commit}, payload) {
      commit('SET_REGION', payload)
    },
  },
  getters: {
    fieldPrefix (state) {
      const region = regions.find( r => state.selected_region === r[`label_${state.language}`])
      return region.prefix ? region.prefix : 'ca'
    }
  },
  modules: {
  }
})

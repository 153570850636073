<template>
  <div>  
    <CButton
    :display="['none', 'block']"
    variant="ghost" 
    @click="open"
    size="sm" 
    >
    {{ label(button_label) }}
    </CButton>
    <c-modal
      :is-open="isOpen"
      :on-close="close"
      :closeOnOverlayClick="false"
      scroll-behavior="inside"
      size="xl"
    >
      <c-modal-content ref="content" >
        <c-modal-body>
          <div>
            <slot />
            <!-- <content /> -->

          </div>
        </c-modal-body>
        <c-modal-footer>
          <c-button @click="close">X</c-button>
        </c-modal-footer>
      </c-modal-content>
      <c-modal-overlay />
    </c-modal>
  </div>
</template>

<script>
import {labels} from '../config'
import { mapState } from 'vuex'

export default {

  props: {
    button_label: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      isOpen: false,
      scrollBehavior: 'inside',
      labels: labels
    }
  },
  methods: {
    open() {
      this.isOpen = true
    },
    close() {
      this.isOpen = false
    },
    label (label) {
      return label ? this.labels[label][this.language] : ''
    }
  },
  computed: {
    ...mapState(['language'])
  }
}
</script>
<style>
 
</style>
// This file is autogenerated

export const taxonomic = {
    '2020': {
        'en': [
            "Springtails",
            "Beetles",
            "Ants",
            "Sawflies",
            "Slime moulds",
            "Dragonflies and damselflies",
            "Birds",
            "Scorpionflies",
            "Amphibians",
            "Pseudoscorpions",
            "Water mites",
            "Bivalves",
            "Sponges",
            "Caddisflies",
            "Spiders",
            "Bryophytes",
            "Moths and butterflies",
            "Selected flies",
            "Macrofungi",
            "Lacewings",
            "Vascular plants",
            "Lichens",
            "Sea urchins",
            "Fleas",
            "Cephalopods",
            "Harvestmen",
            "Decapods",
            "Stoneflies",
            "Earthworms",
            "Solifuges",
            "Fishes",
            "True bugs",
            "Bees",
            "Corals",
            "Yellowjackets and relatives",
            "Ticks",
            "Sea stars",
            "Mayflies",
            "Reptiles",
            "Myriapods",
            "Horseshoe crabs",
            "Mammals",
            "Grasshoppers and relatives",
            "Sea cucumbers",
            "Terrestrial and freshwater snails and slugs",
            "Leeches"
        ],
        'fr': [
            "Coléoptères",
            "Sangsues",
            "Plécoptères",
            "Oursins",
            "Escargots et limaces terrestres et d'eau douce",
            "Poissons",
            "Macrochampignons",
            "Trichoptères",
            "Céphalopodes",
            "Plantes vasculaires",
            "Mécoptères",
            "Pseudoscorpions",
            "Coraux",
            "Éponges",
            "Éphémères",
            "Bivalves",
            "Astéries",
            "Certaines mouches",
            "Bryophytes",
            "Abeilles",
            "Mammifères",
            "Araignées",
            "Libellules et demoiselles",
            "Vespes et semblables",
            "Décapodes",
            "Hydracariens",
            "Amphibiens",
            "Tiques",
            "Lichens",
            "Oiseaux",
            "Limules",
            "Fourmis",
            "Collemboles",
            "Puces",
            "Sauterelles et semblables",
            "Solifuges",
            "Punaises",
            "Papillons",
            "Myriapodes",
            "Myxomycètes",
            "Neuroptères",
            "Reptiles",
            "Opilions",
            "Lombrics",
            "Holothuries",
            "Symphytes"
        ]
    },
    '2015': {
        'en': [
            "Sea urchins",
            "Selected macrofungi",
            "Decapods",
            "Beetles",
            "Stoneflies",
            "Ants",
            "Fishes",
            "Bees",
            "Corals",
            "Yellowjacket wasps",
            "Dragonflies and damselflies",
            "Scorpionflies",
            "Flower flies",
            "Birds",
            "Amphibians",
            "Macrolichens",
            "Horse flies",
            "Mosquitoes",
            "Mayflies",
            "Freshwater bivalves",
            "Sponges",
            "Caddisflies",
            "Reptiles",
            "Spiders",
            "Bryophytes",
            "Bee flies",
            "Moths and butterflies",
            "Grasshoppers and relatives",
            "Mammals",
            "Black flies",
            "Lacewings",
            "Sea cucumbers",
            "Vascular plants",
            "Terrestrial and freshwater snails and slugs"
        ],
        'fr': [
            "Amphibiens",
            "Coléoptères",
            "Plécoptères",
            "Oursins",
            "Certains macrochampignons",
            "Oiseaux",
            "Fourmis",
            "Sauterelles et semblables",
            "Simulies",
            "Escargots et limaces terrestres et d'eau douce",
            "Poissons",
            "Bivalves d'eau douce",
            "Trichoptères",
            "Plantes vasculaires",
            "Bombyles",
            "Papillons",
            "Mécoptères",
            "Coraux",
            "Éponges",
            "Macrolichens",
            "Éphémères",
            "Neuroptères",
            "Reptiles",
            "Bryophytes",
            "Abeilles",
            "Mammifères",
            "Araignées",
            "Holothuries",
            "Libellules et demoiselles",
            "Guêpes jaunes",
            "Syrphes",
            "Décapodes",
            "Taons",
            "Moustiques"
        ]

    },
    '2010': {
        'en': [ 
            "Crayfishes",
            "Freshwater mussels",
            "Lady Beetles",
            "Birds",
            "Butterflies",
            "Amphibians",
            "Horse flies",
            "Macrolichens",
            "Mosquitoes",
            "Odonates",
            "Mosses",
            "Reptiles",
            "Ground beetles",
            "Spiders",
            "Bumblebees",
            "Mammals",
            "Selected macromoths",
            "Black flies",
            "Predaceous diving beetles",
            "Vascular plants"
        ],
        'fr': [
            "Oiseaux",
            "Certains papillons de nuit",
            "Dytiques",
            "Moules d'eau douce",
            "Plantes vasculaires",
            "Mouches à cheval",
            "Papillons",
            "Macrolichens",
            "Écrevisses",
            "Odonates",
            "Bourdons",
            "Reptiles",
            "Carabes",
            "Mouches noires",
            "Mousses",
            "Mammifères",
            "Araignées",
            "Coccinelles",
            "Moustiques",
            "Amphibiens"
        ]
    },
    '2005': {
        'en': [
            "Crayfishes",
            "Tiger beetles",
            "Freshwater mussels",
            "Fishes",
            "Birds",
            "Amphibians",
            "Odonates",
            "Reptiles",
            "Mammals",
            "Vascular plants"
        ],
        'fr': [
            "Oiseaux",
            "Poissons",
            "Moules d'eau douce",
            "Plantes vasculaires",
            "Cicindèles",
            "Écrevisses",
            "Odonates",
            "Reptiles",
            "Mammifères",
            "Amphibiens"
        ]
    },
    '2000': {
        'en': [
            "Ferns",
            "Birds",
            "Butterflies",
            "Amphibians",
            "Freshwater fishes",
            "Reptiles",
            "Mammals",
            "Orchids"
        ],
        'fr': [
            "Oiseaux",
            "Poissons d'eau douce",
            "Orchidées",
            "Papillons",
            "Fougères",
            "Reptiles",
            "Mammifères",
            "Amphibiens"
        ]
    }
}

export const ca_map_rank = {
    '2020': [
        "3",
        "X",
        "2",
        "R",
        "U",
        "A",
        "5",
        "H",
        "1",
        "4"
    ],
    '2015': [
        "3",
        "X",
        "2",
        "R",
        "U",
        "A",
        "5",
        "H",
        "1",
        "4"
    ],
    '2010': [
        "1",
        "4",
        "8",
        "6",
        "3",
        "0.1",
        "0.2",
        "2",
        "7",
        "5"
    ],
    
}

<template>
    <CBox width="100%">
        <CFlex direction="row" width="100%" bg="black" align="left" justify="center">
            <CFlex :width="['100%']" max-width="1440px">
                <CLink :href="label('wildspecies_uri')">
                    <CImage :src="label('logo_src')" :width="['60px','120px']" :display="['none', 'none', 'block']" />
                </CLink>
                <CBox flex="1" :pl="[1, 3]" pr="3">
                    <CFlex width="100%" direction="column">
                        <CBox align="right" color="green.500" :display="['none', 'block']" z-index="10" :mr="2">
                            <a href="" style="padding: 0 10px" @click.prevent="setLanguage('en')">en</a>
                            <a href="" @click.prevent="setLanguage('fr')">fr</a>
                        </CBox>
                        <CBox width="100%" :pb="[3,3,1]" :px="[1,1,2]" py="2">
                            <slot />
                        </CBox>
                    </CFlex>
                </CBox>
            </CFlex>
        </CFlex>
    </CBox>
</template>

<script>

import {
    CFlex, 
    CBox,
    CImage
} from "@chakra-ui/vue"

import { labels } from '../config'
import { mapState } from 'vuex'

export default {
    components: {
        CFlex, 
        CBox, 
        CImage
    },
    methods: {
        setLanguage(newLang) {
            this.$store.dispatch('setLanguage', newLang)
            this.$router.push({
                name: this.$router.name,
                params: {
                    query_language: newLang
                },
                query: {
                    ...this.$route.query
                }
            })
        },
        label(label) {
            return labels[label][this.language];
        },
    },
    computed: {
        ...mapState(['language'])
    }
}
</script>

<style>
    /* Hide scrollbar for Chrome, Safari and Opera */
    #filter-col::-webkit-scrollbar {
    display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    #filter-col {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    }
</style>
<template>
    <div>
        <CIconButton aria-label="Show/Hide Filters" variant="ghost" icon="bars" @click="isOpen = !isOpen" />

        <div class="sidenav" :class="isOpen ? 'sidenav-open' : ''">
            <CFlex align="right">

                <CBox flex="1">
                    <CBox align="right" color="green.500" z-index="10" :mr="2">
                        <a href="" style="padding: 0 10px" @click.prevent="setLanguage('en')">en</a>
                        <a href="" @click.prevent="setLanguage('fr')">fr</a>
                    </CBox>
                </CBox>
                <CIconButton aria-label="Show/Hide Filters" variant="ghost" icon="times" @click="isOpen = !isOpen" :pr="2" />
            </CFlex>
            <filters />
        </div>
    </div>
</template>

<script>
import Filters from './Filters.vue'
export default {
    props: [''],
    components: {
        Filters
    },
    data () {
        return {
            isOpen: false
        }
    },
    methods: {
        close () {
            this.isOpen = false
        },

        setLanguage(newLang) {
            this.$store.dispatch('setLanguage', newLang)
        },
    }
}
</script>

<style>
/* The side navigation menu */
.sidenav {
  height: 100%; 
  width: 0;
  position: fixed; 
  z-index: 1; 
  top: 0;
  left: 0px;
  background-color: #fff; 
  overflow-x: hidden; 
  padding-top: 10px; 
  transition: 0.5s; 
}

.sidenav-open {
    width: 340px;
    max-width: "100%"
}
</style>
<template>
<div>    
    <h2 class="c3">D&eacute;finition des rangs</h2>
    <h2>2015 ou plus r&eacute;cent:</h2>
   <table class="c14">
      <tbody>
        <tr class="c4">
          <td class="c2" colspan="1" rowspan="1">
            <p class="c5"><span class="c0">Rang</span></p>
          </td>
          <td class="c1" colspan="1" rowspan="1">
            <p class="c3"><span class="c0">Cat&eacute;gorie</span></p>
          </td>
          <td class="c6" colspan="1" rowspan="1">
            <p class="c3"><span class="c0">Description</span></p>
          </td>
        </tr>
        <tr class="c4">
          <td class="c22" colspan="3" rowspan="1">
            <p class="c3 c8"><span class="c0"></span></p>
            <p class="c3">
              <span class="c12">&Eacute;chelle g&eacute;ographique</span>
            </p>
          </td>
        </tr>
        <tr class="c4">
          <td class="c2" colspan="1" rowspan="1">
            <p class="c5"><span class="c0">N</span></p>
          </td>
          <td class="c1" colspan="1" rowspan="1">
            <p class="c3"><span class="c0">National</span></p>
          </td>
          <td class="c6" colspan="1" rowspan="1">
            <p class="c3">
              <span class="c0"
                >Indique un rang &agrave; l&rsquo;&eacute;chelle nationale au
                Canada.</span
              >
            </p>
          </td>
        </tr>
        <tr class="c4">
          <td class="c2" colspan="1" rowspan="1">
            <p class="c5"><span class="c0">S</span></p>
          </td>
          <td class="c1" colspan="1" rowspan="1">
            <p class="c3"><span class="c0">Subnational</span></p>
          </td>
          <td class="c6" colspan="1" rowspan="1">
            <p class="c3">
              <span class="c0"
                >Indique un rang &agrave; l&rsquo;&eacute;chelle d&rsquo;une
                province, d&rsquo;un territoire, ou d&rsquo;une r&eacute;gion
                oc&eacute;anique au Canada.</span
              >
            </p>
          </td>
        </tr>
        <tr class="c4">
          <td class="c22" colspan="3" rowspan="1">
            <p class="c3 c8"><span class="c0"></span></p>
            <p class="c3"><span class="c12">Statut de conservation</span></p>
          </td>
        </tr>
        <tr class="c4">
          <td class="c2 c23" colspan="1" rowspan="1">
            <p class="c5"><span class="c10">X</span></p>
          </td>
          <td class="c1" colspan="1" rowspan="1">
            <p class="c3">
              <span class="c0">Pr&eacute;sum&eacute;e disparue</span>
            </p>
          </td>
          <td class="c6" colspan="1" rowspan="1">
            <p class="c3">
              <span class="c0"
                >L&rsquo;esp&egrave;ce est soup&ccedil;onn&eacute;e &ecirc;tre
                disparue de la juridiction (nation, province, territoire, ou
                r&eacute;gion oc&eacute;anique). Pas localis&eacute;e
                malgr&eacute; d&rsquo;intensives recherches dans les sites
                historiques et d&rsquo;autres habitats appropri&eacute;s, et
                pratiquement pas de chance d&rsquo;&ecirc;tre
                red&eacute;couverte.</span
              >
            </p>
          </td>
        </tr>
        <tr class="c4">
          <td class="c2 c30" colspan="1" rowspan="1">
            <p class="c5"><span class="c0">H</span></p>
          </td>
          <td class="c1" colspan="1" rowspan="1">
            <p class="c3"><span class="c0">Possiblement disparue</span></p>
          </td>
          <td class="c6" colspan="1" rowspan="1">
            <p class="c3">
              <span class="c0"
                >Connue seulement &agrave; partir d&rsquo;observations
                historiques, mais avec de l&rsquo;espoir d&rsquo;&ecirc;tre
                red&eacute;couverte. Il existe des indices que
                l&rsquo;esp&egrave;ce pourrait ne plus &ecirc;tre
                pr&eacute;sente dans la juridiction, mais pas assez pour
                l&rsquo;affirmer avec certitude. Ces indices incluent par
                exemple: (1) que l&rsquo;esp&egrave;ce n&rsquo;a pas
                &eacute;t&eacute; document&eacute;e depuis approximativement
                20-40 ans, malgr&eacute; quelques recherches et/ou quelques
                preuves de pertes ou d&eacute;gradations significatives de
                l&rsquo;habitat; (2) que des recherches infructueuses ont
                &eacute;t&eacute; men&eacute;es pour l&rsquo;esp&egrave;ce, mais
                pas de fa&ccedil;on assez syst&eacute;matique pour
                pr&eacute;sumer qu&rsquo;elle n&rsquo;est plus pr&eacute;sente
                dans la juridiction.</span
              >
            </p>
          </td>
        </tr>
        <tr class="c4">
          <td class="c2 c21" colspan="1" rowspan="1">
            <p class="c5"><span class="c0">1</span></p>
          </td>
          <td class="c1" colspan="1" rowspan="1">
            <p class="c3"><span class="c0">Gravement en p&eacute;ril</span></p>
          </td>
          <td class="c6" colspan="1" rowspan="1">
            <p class="c3">
              <span class="c0"
                >&Agrave; tr&egrave;s haut risque de disparition de la
                juridiction suite &agrave; une r&eacute;partition tr&egrave;s
                restreinte, &agrave; tr&egrave;s peu de populations ou
                d&rsquo;occurrences, &agrave; de tr&egrave;s forts
                d&eacute;clins, &agrave; des menaces s&eacute;v&egrave;res, ou
                d&rsquo;autres facteurs.</span
              >
            </p>
          </td>
        </tr>
        <tr class="c4">
          <td class="c2 c9" colspan="1" rowspan="1">
            <p class="c5"><span class="c0">2</span></p>
          </td>
          <td class="c1" colspan="1" rowspan="1">
            <p class="c3"><span class="c0">En p&eacute;ril</span></p>
          </td>
          <td class="c6" colspan="1" rowspan="1">
            <p class="c3">
              <span class="c0"
                >&Agrave; haut risque de disparition de la juridiction suite
                &agrave; une r&eacute;partition restreinte, &agrave; peu de
                populations ou d&rsquo;occurrences, &agrave; de forts
                d&eacute;clins, &agrave; des menaces s&eacute;v&egrave;res, ou
                d&rsquo;autres facteurs.</span
              >
            </p>
          </td>
        </tr>
        <tr class="c4">
          <td class="c2 c29" colspan="1" rowspan="1">
            <p class="c5"><span class="c0">3</span></p>
          </td>
          <td class="c1" colspan="1" rowspan="1">
            <p class="c3"><span class="c0">Vuln&eacute;rable</span></p>
          </td>
          <td class="c6" colspan="1" rowspan="1">
            <p class="c3">
              <span class="c0"
                >&Agrave; risque mod&eacute;r&eacute; de disparition de la
                juridiction suite &agrave; une r&eacute;partition assez
                restreinte, &agrave; relativement peu de populations ou
                d&rsquo;occurrences, &agrave; des d&eacute;clins r&eacute;cents
                et g&eacute;n&eacute;ralis&eacute;s, &agrave; des menaces, ou
                d&rsquo;autres facteurs.</span
              >
            </p>
          </td>
        </tr>
        <tr class="c4">
          <td class="c2 c27" colspan="1" rowspan="1">
            <p class="c5"><span class="c0">4</span></p>
          </td>
          <td class="c1" colspan="1" rowspan="1">
            <p class="c3">
              <span class="c0">Apparemment en s&eacute;curit&eacute;</span>
            </p>
          </td>
          <td class="c6" colspan="1" rowspan="1">
            <p class="c3">
              <span class="c0"
                >&Agrave; risque assez bas de disparition de la juridiction
                suite &agrave; une vaste r&eacute;partition et/ou plusieurs
                populations ou occurrences, mais ayant des causes possibles de
                pr&eacute;occupations r&eacute;sultant de d&eacute;clins locaux
                r&eacute;cents, de menaces, ou d&rsquo;autres facteurs.</span
              >
            </p>
          </td>
        </tr>
        <tr class="c4">
          <td class="c2 c24" colspan="1" rowspan="1">
            <p class="c5"><span class="c0">5</span></p>
          </td>
          <td class="c1" colspan="1" rowspan="1">
            <p class="c3"><span class="c0">En s&eacute;curit&eacute;</span></p>
          </td>
          <td class="c6" colspan="1" rowspan="1">
            <p class="c3">
              <span class="c0"
                >&Agrave; tr&egrave;s faible ou absence de risque de disparition
                de la juridiction suite &agrave; une tr&egrave;s vaste
                r&eacute;partition, &agrave; des populations ou occurrences
                abondantes, avec peu ou pas de pr&eacute;occupations
                r&eacute;sultant de d&eacute;clins ou de menaces.</span
              >
            </p>
          </td>
        </tr>
        <tr class="c4">
          <td class="c2 c17" colspan="1" rowspan="1">
            <p class="c5"><span class="c0">U</span></p>
          </td>
          <td class="c1" colspan="1" rowspan="1">
            <p class="c3"><span class="c0">Inclassable</span></p>
          </td>
          <td class="c6" colspan="1" rowspan="1">
            <p class="c3">
              <span class="c0"
                >Pr&eacute;sentement inclassable suite &agrave; un manque
                d&rsquo;informations ou suite &agrave; des informations montrant
                des contradictions substantielles sur le statut ou les
                tendances.</span
              >
            </p>
          </td>
        </tr>
        <tr class="c4">
          <td class="c2 c25" colspan="1" rowspan="1">
            <p class="c5"><span class="c0">NR</span></p>
          </td>
          <td class="c1" colspan="1" rowspan="1">
            <p class="c3"><span class="c0">Non class&eacute;e</span></p>
          </td>
          <td class="c6" colspan="1" rowspan="1">
            <p class="c3">
              <span class="c0"
                >&Eacute;valuation du statut de conservation national ou
                subnational pas encore r&eacute;alis&eacute;e.</span
              >
            </p>
          </td>
        </tr>
        <tr class="c4">
          <td class="c2 c20" colspan="1" rowspan="1">
            <p class="c5"><span class="c7">NA</span></p>
          </td>
          <td class="c1" colspan="1" rowspan="1">
            <p class="c3"><span class="c0">Non applicable</span></p>
          </td>
          <td class="c6" colspan="1" rowspan="1">
            <p class="c3">
              <span class="c0"
                >Un statut de conservation n&rsquo;est pas applicable parce que
                l&rsquo;esp&egrave;ce n&rsquo;est pas une cible
                appropri&eacute;e pour des activit&eacute;s de conservation.
                Cela inclut les esp&egrave;ces exotiques (qui ont
                &eacute;t&eacute; d&eacute;plac&eacute;es au-del&agrave; de leur
                aire de r&eacute;partition naturelle suite &agrave; des
                activit&eacute;s humaines) ou les esp&egrave;ces accidentelles
                (se trouvant peu souvent et de mani&egrave;re
                impr&eacute;visible &agrave; l&rsquo;ext&eacute;rieur de leur
                aire de r&eacute;partition habituelle).</span
              >
            </p>
          </td>
        </tr>
        <tr class="c4">
          <td class="c22" colspan="3" rowspan="1">
            <p class="c3 c8"><span class="c0"></span></p>
            <p class="c3"><span class="c12">Qualificatif</span></p>
          </td>
        </tr>
        <tr class="c4">
          <td class="c2" colspan="1" rowspan="1">
            <p class="c5"><span class="c0">?</span></p>
          </td>
          <td class="c1" colspan="1" rowspan="1">
            <p class="c3">
              <span class="c0">Rang num&eacute;rique inexact</span>
            </p>
          </td>
          <td class="c6" colspan="1" rowspan="1">
            <p class="c3">
              <span class="c0"
                >D&eacute;note un rang num&eacute;rique inexact. Cette
                d&eacute;signation ne devrait pas &ecirc;tre utilis&eacute;e
                avec tous les rangs X, H, U, NR ou NA des statuts de
                conservation.</span
              >
            </p>
          </td>
        </tr>
        <tr class="c4">
          <td class="c2" colspan="1" rowspan="1">
            <p class="c5"><span class="c0">B</span></p>
          </td>
          <td class="c1" colspan="1" rowspan="1">
            <p class="c3"><span class="c0">Reproductive</span></p>
          </td>
          <td class="c6" colspan="1" rowspan="1">
            <p class="c3">
              <span class="c0"
                >Le statut de conservation r&eacute;f&egrave;re &agrave; la
                population reproductive de l&rsquo;esp&egrave;ce dans la nation,
                la province, le territoire, ou la r&eacute;gion
                oc&eacute;anique.</span
              >
            </p>
          </td>
        </tr>
        <tr class="c4">
          <td class="c2" colspan="1" rowspan="1">
            <p class="c5"><span class="c0">N</span></p>
          </td>
          <td class="c1" colspan="1" rowspan="1">
            <p class="c3"><span class="c0">Non reproductive</span></p>
          </td>
          <td class="c6" colspan="1" rowspan="1">
            <p class="c3">
              <span class="c0"
                >Le statut de conservation r&eacute;f&egrave;re &agrave; la
                population non reproductive de l&rsquo;esp&egrave;ce dans la
                nation, la province, le territoire, ou la r&eacute;gion
                oc&eacute;anique.</span
              >
            </p>
          </td>
        </tr>
        <tr class="c4">
          <td class="c2" colspan="1" rowspan="1">
            <p class="c5"><span class="c0">M</span></p>
          </td>
          <td class="c1" colspan="1" rowspan="1">
            <p class="c3"><span class="c0">Migratrice</span></p>
          </td>
          <td class="c6" colspan="1" rowspan="1">
            <p class="c3">
              <span class="c0"
                >Le statut de conservation r&eacute;f&egrave;re &agrave; la
                population migratrice de l&rsquo;esp&egrave;ce dans la nation,
                la province, le territoire, ou la r&eacute;gion
                oc&eacute;anique.</span
              >
            </p>
          </td>
        </tr>
      </tbody>
    </table>
    <h2>2000, 2005 et 2010:</h2>
    <table class="c14">
      <tbody>
        <tr class="c4">
          <td class="c2" colspan="1" rowspan="1">
            <p class="c5"><span class="c0">Rang</span></p>
          </td>
          <td class="c1" colspan="1" rowspan="1">
            <p class="c3"><span class="c0">Cat&eacute;gorie</span></p>
          </td>
          <td class="c6" colspan="1" rowspan="1">
            <p class="c3"><span class="c0">Description</span></p>
          </td>
        </tr>
        <tr class="c4">
          <td class="c2 c28" colspan="1" rowspan="1">
            <p class="c5"><span class="c0">0.2</span></p>
          </td>
          <td class="c1" colspan="1" rowspan="1">
            <p class="c3"><span class="c0">Disparue</span></p>
          </td>
          <td class="c6" colspan="1" rowspan="1">
            <p class="c3">
              <span class="c0"
                >Esp&egrave;ce &eacute;limin&eacute;e &agrave;
                l&rsquo;&eacute;chelle mondiale (elle n&rsquo;existe plus nulle
                part).</span
              >
            </p>
          </td>
        </tr>
        <tr class="c4">
          <td class="c2 c11" colspan="1" rowspan="1">
            <p class="c5"><span class="c7">0.1</span></p>
          </td>
          <td class="c1" colspan="1" rowspan="1">
            <p class="c3">
              <span class="c0">Disparue de la r&eacute;gion</span>
            </p>
          </td>
          <td class="c6" colspan="1" rowspan="1">
            <p class="c3">
              <span class="c0"
                >Esp&egrave;ce qui n&rsquo;est plus pr&eacute;sente dans une
                r&eacute;gion g&eacute;ographique donn&eacute;e, mais qui
                l&rsquo;est ailleurs.</span
              >
            </p>
          </td>
        </tr>
        <tr class="c4">
          <td class="c2 c21" colspan="1" rowspan="1">
            <p class="c5"><span class="c0">1</span></p>
          </td>
          <td class="c1" colspan="1" rowspan="1">
            <p class="c3"><span class="c0">En p&eacute;ril</span></p>
          </td>
          <td class="c6" colspan="1" rowspan="1">
            <p class="c3">
              <span class="c0"
                >Esp&egrave;ce pour laquelle une &eacute;valuation officielle
                d&eacute;taill&eacute;e du risque (&eacute;valuations du COSEPAC
                ou leurs &eacute;quivalents provinciaux ou territoriaux) a
                &eacute;t&eacute; effectu&eacute;e et pour laquelle il a
                &eacute;t&eacute; &eacute;tabli qu&rsquo;elle risque de
                dispara&icirc;tre de la plan&egrave;te ou du Canada
                (c&rsquo;est-&agrave;-dire qu&rsquo;elle est en voie de
                disparition ou menac&eacute;e). Une esp&egrave;ce
                d&eacute;sign&eacute;e &laquo; en voie de disparition &raquo; ou
                &laquo; menac&eacute;e &raquo; par le COSEPAC est class&eacute;e
                d&rsquo;office &agrave; l&rsquo;&eacute;chelle nationale parmi
                les esp&egrave;ces en p&eacute;ril. Lorsqu&rsquo;une
                &eacute;valuation provinciale ou territoriale officielle des
                risques conclut qu&rsquo;une esp&egrave;ce est &laquo; en voie
                de disparition &raquo; ou &laquo; menac&eacute;e &raquo; dans
                cette r&eacute;gion particuli&egrave;re, cette esp&egrave;ce est
                alors automatiquement d&eacute;sign&eacute;e, en vertu du
                programme de classification de la situation
                g&eacute;n&eacute;rale, comme une esp&egrave;ce en p&eacute;ril
                &agrave; l&rsquo;&eacute;chelle provinciale ou
                territoriale.</span
              >
            </p>
          </td>
        </tr>
        <tr class="c4">
          <td class="c2 c9" colspan="1" rowspan="1">
            <p class="c5"><span class="c0">2</span></p>
          </td>
          <td class="c1" colspan="1" rowspan="1">
            <p class="c3">
              <span class="c0">Possiblement en p&eacute;ril</span>
            </p>
          </td>
          <td class="c6" colspan="1" rowspan="1">
            <p class="c3">
              <span class="c0"
                >Esp&egrave;ce qui est peut-&ecirc;tre menac&eacute;e de
                disparition, ou de disparition de la r&eacute;gion, et qui est
                donc admissible &agrave; une &eacute;valuation
                d&eacute;taill&eacute;e des risques par le COSEPAC ou ses
                &eacute;quivalents provinciaux ou territoriaux.</span
              >
            </p>
          </td>
        </tr>
        <tr class="c4">
          <td class="c2 c29" colspan="1" rowspan="1">
            <p class="c5"><span class="c0">3</span></p>
          </td>
          <td class="c1" colspan="1" rowspan="1">
            <p class="c3"><span class="c0">Sensible</span></p>
          </td>
          <td class="c6" colspan="1" rowspan="1">
            <p class="c3">
              <span class="c0"
                >Esp&egrave;ce dont on ne croit pas qu&rsquo;elle est en danger
                imm&eacute;diat de disparition ou de disparition de la
                r&eacute;gion, mais qui pourrait n&eacute;cessiter une attention
                ou une protection particuli&egrave;re pour ne pas devenir en
                p&eacute;ril.</span
              >
            </p>
          </td>
        </tr>
        <tr class="c4">
          <td class="c2 c24" colspan="1" rowspan="1">
            <p class="c5"><span class="c0">4</span></p>
          </td>
          <td class="c1" colspan="1" rowspan="1">
            <p class="c3"><span class="c0">En s&eacute;curit&eacute;</span></p>
          </td>
          <td class="c6" colspan="1" rowspan="1">
            <p class="c3">
              <span class="c0"
                >Esp&egrave;ce dont on ne croit pas qu&rsquo;elle est disparue,
                disparue de la r&eacute;gion, en p&eacute;ril, possiblement en
                p&eacute;ril, sensible, occasionnelle ni exotique. Cette
                cat&eacute;gorie inclut certaines esp&egrave;ces dont le nombre
                a tendance &agrave; diminuer au Canada, mais qui sont encore
                relativement r&eacute;pandues ou abondantes.</span
              >
            </p>
          </td>
        </tr>
        <tr class="c4">
          <td class="c2 c17" colspan="1" rowspan="1">
            <p class="c5"><span class="c0">5</span></p>
          </td>
          <td class="c1" colspan="1" rowspan="1">
            <p class="c3"><span class="c0">Ind&eacute;termin&eacute;e</span></p>
          </td>
          <td class="c6" colspan="1" rowspan="1">
            <p class="c3">
              <span class="c0"
                >Esp&egrave;ce pour laquelle les donn&eacute;es, les
                renseignements et les connaissances sont insuffisantes pour
                &eacute;valuer sa situation avec assurance.</span
              >
            </p>
          </td>
        </tr>
        <tr class="c4">
          <td class="c2 c25" colspan="1" rowspan="1">
            <p class="c5"><span class="c0">6</span></p>
          </td>
          <td class="c1" colspan="1" rowspan="1">
            <p class="c3"><span class="c0">Non &eacute;valu&eacute;e</span></p>
          </td>
          <td class="c6" colspan="1" rowspan="1">
            <p class="c3">
              <span class="c0"
                >Esp&egrave;ce dont on sait ou croit qu&rsquo;elle est
                r&eacute;guli&egrave;rement pr&eacute;sente dans la
                r&eacute;gion g&eacute;ographique du Canada, &agrave; laquelle
                la classification s&rsquo;applique, mais qui n&rsquo;a pas
                encore &eacute;t&eacute; &eacute;valu&eacute;e dans le cadre du
                programme de la situation g&eacute;n&eacute;rale.</span
              >
            </p>
          </td>
        </tr>
        <tr class="c4">
          <td class="c2 c20" colspan="1" rowspan="1">
            <p class="c5"><span class="c7">7</span></p>
          </td>
          <td class="c1" colspan="1" rowspan="1">
            <p class="c3"><span class="c0">Exotique</span></p>
          </td>
          <td class="c6" colspan="1" rowspan="1">
            <p class="c3">
              <span class="c0"
                >Esp&egrave;ce que des activit&eacute;s anthropiques ont
                d&eacute;plac&eacute;e au-del&agrave; de son aire de
                r&eacute;partition naturelle. Dans le pr&eacute;sent rapport,
                les esp&egrave;ces exotiques ont &eacute;t&eacute;
                d&eacute;lib&eacute;r&eacute;ment exclues de toutes les autres
                cat&eacute;gories.</span
              >
            </p>
          </td>
        </tr>
        <tr class="c4">
          <td class="c2 c26" colspan="1" rowspan="1">
            <p class="c5"><span class="c19">8</span></p>
          </td>
          <td class="c1" colspan="1" rowspan="1">
            <p class="c3"><span class="c0">Occasionnelle</span></p>
          </td>
          <td class="c6" colspan="1" rowspan="1">
            <p class="c3">
              <span class="c0"
                >Esp&egrave;ce qui se trouve peu souvent et de mani&egrave;re
                impr&eacute;visible &agrave; l&rsquo;ext&eacute;rieur de son
                aire de r&eacute;partition habituelle.</span
              >
            </p>
          </td>
        </tr>
      </tbody>
    </table>
</div>
</template>

<script>
export default {

}
</script>
<style scoped>
      /* @import url("https://themes.googleusercontent.com/fonts/css?kit=fpjTOVmNbO4Lz34iLyptLUXza5VhXqVC6o75Eld_V98"); */
      ol {
        margin: 0;
        padding: 0;
      }
      table td,
      table th {
        padding: 0;
      }
      .c1 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 86.5pt;
        border-top-color: #000000;
        border-bottom-style: solid;
      }
      .c22 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 388.6pt;
        border-top-color: #000000;
        border-bottom-style: solid;
      }
      .c2 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 69.2pt;
        border-top-color: #000000;
        border-bottom-style: solid;
      }
      .c6 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 232.9pt;
        border-top-color: #000000;
        border-bottom-style: solid;
      }
      .c16 {
        -webkit-text-decoration-skip: none;
        color: #000000;
        font-weight: 400;
        text-decoration: underline;
        vertical-align: baseline;
        text-decoration-skip-ink: none;
        font-size: 11pt;
        font-family: "Calibri";
        font-style: normal;
      }
      .c0 {
        color: #000000;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 11pt;
        font-family: "Calibri";
        font-style: normal;
      }
      .c12 {
        color: #000000;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 11pt;
        font-family: "Calibri";
        font-style: italic;
      }
      .c7 {
        color: #ffffff;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 11pt;
        font-family: "Calibri";
        font-style: normal;
      }
      .c13 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1;
        orphans: 2;
        widows: 2;
        text-align: justify;
      }
      .c5 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1;
        orphans: 2;
        widows: 2;
        text-align: center;
      }
      .c3 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
      .c15 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.15;
        text-align: left;
      }
      .c14 {
        margin-left: auto;
        border-spacing: 0;
        border-collapse: collapse;
        margin-right: auto;
      }
      .c18 {
        background-color: #ffffff;
        max-width: 468pt;
        padding: 72pt 72pt 72pt 72pt;
      }
      .c4 {
        height: 0pt;
      }
      .c17 {
        background-color: #c0c0c0;
      }
      .c8 {
        height: 11pt;
      }
      .c10 {
        color: #ffffff;
      }
      .c24 {
        background-color: #339966;
      }
      .c19 {
        color: #000000;
      }
      .c23 {
        background-color: #0000cc;
      }
      .c29 {
        background-color: #ffff00;
      }
      .c30 {
        background-color: #6699ff;
      }
      .c28 {
        background-color: #3366ff;
      }
      .c20 {
        background-color: #000000;
      }
      .c21 {
        background-color: #ff0000;
      }
      .c11 {
        background-color: #800080;
      }
      .c26 {
        background-color: #00ffff;
      }
      .c9 {
        background-color: #ff9900;
      }
      .c25 {
        background-color: #808080;
      }
      .c27 {
        background-color: #66ff66;
      }
      .title {
        padding-top: 24pt;
        color: #000000;
        font-weight: 700;
        font-size: 36pt;
        padding-bottom: 6pt;
        font-family: "Calibri";
        line-height: 1;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
      .subtitle {
        padding-top: 18pt;
        color: #666666;
        font-size: 24pt;
        padding-bottom: 4pt;
        font-family: "Georgia";
        line-height: 1;
        page-break-after: avoid;
        font-style: italic;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
      li {
        color: #000000;
        font-size: 11pt;
        font-family: "Calibri";
      }
      p {
        margin: 0;
        color: #000000;
        font-size: 11pt;
        font-family: "Calibri";
      }
      h1 {
        padding-top: 24pt;
        color: #000000;
        font-weight: 700;
        font-size: 24pt;
        padding-bottom: 6pt;
        font-family: "Calibri";
        line-height: 1;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
      h2 {
        padding-top: 18pt;
        color: #000000;
        font-weight: 700;
        font-size: 18pt;
        padding-bottom: 4pt;
        font-family: "Calibri";
        line-height: 1;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
      h3 {
        padding-top: 14pt;
        color: #000000;
        font-weight: 700;
        font-size: 14pt;
        padding-bottom: 4pt;
        font-family: "Calibri";
        line-height: 1;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
      h4 {
        padding-top: 12pt;
        color: #000000;
        font-weight: 700;
        font-size: 12pt;
        padding-bottom: 2pt;
        font-family: "Calibri";
        line-height: 1;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
      h5 {
        padding-top: 11pt;
        color: #000000;
        font-weight: 700;
        font-size: 11pt;
        padding-bottom: 2pt;
        font-family: "Calibri";
        line-height: 1;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
      h6 {
        padding-top: 10pt;
        color: #000000;
        font-weight: 700;
        font-size: 10pt;
        padding-bottom: 2pt;
        font-family: "Calibri";
        line-height: 1;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
    </style>

